<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container my-auto">
      <h5>{{ 'pages.default.patient_materials.learning_materials' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!isLoading && !flatMaterialPhases?.length">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.patient_materials.no_materials_yet' | translate }}</h6>
    </div>


    <div class="row" *ngIf="!isLoading && flatMaterialPhases?.length">
      <div class="col-3">
        <div class="phases-legend-nav">
          <p class="caption extra-small mb-2">{{ 'pages.default.patient_materials.your_materials' | translate }}</p>

          <a
            href=""
            (click)="navClick($event, phaseWM)"
            *ngFor="let phaseWM of flatMaterialPhases; index as index; last as last"
            class="item"
            [ngClass]="{
              'sub': phaseWM.isSubPhase,
              'active': activePhaseID === phaseWM.phase_id
            }"
          >
            {{ phaseWM?.name | translateHelp | translate }}
          </a>
        </div>
      </div>
      <div class="col-9">

        <div
          id="phase_group_{{phaseWM.phase_id}}"
          *ngFor="let phaseWM of flatMaterialPhases; index as index; last as last"
        >

          <ng-container *ngIf="index > 0 && flatMaterialPhases[index-1]?.educational_materials?.length">
            <hr class="my-4" *ngIf="phaseWM?.isSubPhase"/>

            <div class="py-3" *ngIf="!phaseWM?.isSubPhase"></div>
          </ng-container>


          <div class="card phase-info-card mb-4" [ngClass]="{'flatten': phaseWM.isSubPhase}">
            <div class="card-body">
              <div class="phase-info-bar">
                <h6 class="title" [ngClass]="{'h7': phaseWM?.isSubPhase}">{{ phaseWM?.name | translateHelp | translate }}</h6>

                <div class="meta">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      [style.width]="phaseWM?.percentage"
                      [ngClass]="{
                        'bg-success': phaseWM?.isFinished
                      }"
                    ></div>
                  </div>

                  <p class="stats">{{phaseWM?.completed}}/{{phaseWM?.total}} {{ 'pages.default.patient_materials.completed' | translate }}</p>
                </div>
              </div>

              @if (phaseWM?.translated_description) {
                <div class="mt-2" [innerHtml]="phaseWM?.translated_description"></div>
              }
            </div>
          </div>


          <div class="row g-3 gy-4" *ngIf="phaseWM?.educational_materials?.length">
            <div id="e2e_{{phaseWM.phase_id}}_material_{{index}}" class="col-6" *ngFor="let material of phaseWM?.educational_materials; index as index">

              <a href=""
                class="card preview-card h-100"
                [ngClass]="{
                  'completed': material.isCompleted,
                  'unavailable': material.isUnavailable
                }"
                (click)="openMaterial($event, material)"
              >
                <div class="card-cover">
                  <img *ngIf="material?.thumbnail?.image?.url" [src]="material?.thumbnail?.image?.url + '?width=814&disable=upscale&format=pjpg&auto=webp' " alt="Thumbnail" />

                  <div class="cover-meta">
                    <div class="meta-category" *ngIf="material.thumbnail">
                      <span [inlineSVG]="'/assets/svg/file-general-24.svg'" *ngIf="material.isArticle" class="rtl-mirrored-inline-svg"></span>
                      <span [inlineSVG]="'/assets/svg/file-pdf-24.svg'" *ngIf="material.isDocument"></span>
                      <span [inlineSVG]="'/assets/svg/play-24.svg'" *ngIf="material.isMedia" class="rtl-mirrored-inline-svg"></span>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <ng-container *ngIf="!material.isUnavailable">
                    <p class="lead fw-semibold mb-1 e2e-material-title">{{ material.title || 'Material' }}</p>
                    <p class="fw-normal mb-0 preview-description e2e-material-description">{{ material?.description }}</p>
                  </ng-container>

                  <ng-container *ngIf="material.isUnavailable">
                    <p class="lead fw-semibold m-0 e2e-material-title">{{ 'pages.default.patient_materials.unavailable' | translate }}</p>
                  </ng-container>

                  <ng-container *ngIf="!material.isUnavailable">
                    <div class="preview-card-status" *ngIf="material.isStarted">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" [style.width]="material.progress + '%'"></div>
                      </div>

                      <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.progress_played' | translate : {value:material.progress} }}</span>
                      <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.progress_read' | translate : {value:material.progress} }}</span>
                    </div>

                    <div class="preview-card-status" *ngIf="material.isCompleted">
                      <span class="icon in-circle" [inlineSVG]="'/assets/svg/checkmark-24.svg'"></span>
                      <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.played' | translate }}</span>
                      <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.read' | translate }}</span>
                    </div>

                    <div class="preview-card-status" *ngIf="!material.isStarted && !material.isCompleted">
                      <span class="icon" [inlineSVG]="'/assets/svg/info-circle-24.svg'"></span>
                      <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.not_played' | translate }}</span>
                      <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.not_read' | translate }}</span>
                    </div>
                  </ng-container>

                </div>
              </a>

            </div>
          </div>

        <div>


      </div>
    </div>
  </div>
</div>
